/* .seticon{
    background-color: grey;
    border-radius: 10px;
} */
.bg-white{
    width: 275;
}
.headercontent{
    margin-top: 7px;
    margin-bottom: 40px;
    margin-left: 25px;
}

.orderitems .orderpic{
    width: 60%;
    height: 70px;
    border-radius: 12px;
}
.orderitems{
    margin: 0 7px;
}
.orderpic img{
    width: 100px;
    background-color: rgb(167, 167, 167);
    border-radius: 10px;
}

.orderpic{
    margin-bottom: 30px;
}

.quantity{
    /* margin-top: 20px; */
    margin-left: 25px;
}

.totalsale {
    border-bottom: 3px dotted;
    border-right: 10px;
    border-left: 10px;
    padding-bottom: 10px;
}

.total{
    padding-top: 10px;
}
.totalgrid{
    background-color: #ececf4;
    border-radius: 10px;
    margin-left: 0px;
    padding: 10px 12px;
   
}
.buttonstyle{
    width: 100%;
    margin-top: 50px;
    border-radius: 10px;
}

.buttonshop{
    border-radius: 5px;
    width: 28px;
    padding: 2px;
}

.ordername{
    margin-left: 5px;
}
.paymentbtn{
    margin-left: 0px;
}
/* 
.addbutton{
    border-radius: 8px;
    margin-bottom: 4px;
}

.minusbutton{
    border-radius: 8px;
} */
button[class*=bttn] {
    width: 30px;
    height: 30px;
    background-color: #E1E8EE;
    border-radius: 6px;
    border: none;
    cursor: pointer;
}