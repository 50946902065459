.midcontant-top {
    padding: 70px 0 0;
}

.logo-img {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 1.25rem;
    line-height: 1;
    border-radius: 0px;
    overflow: hidden;
    user-select: none;
    width: 100px;
    height: 50px;
}
.searchbtn{
    width: 180px;
}
/* .logo-img {
    width: 100%;
    height: 100%;
    text-align: center;
    object-fit: cover;
    color: transparent;
    text-indent: 10000px;
} */

.secticon {
    cursor: pointer;
    margin-top: 2px;
    padding-right: 10px;
    margin-left: 10px;
}

.badge {
    color: white;
    width: 1rem;
    height: 1rem;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 20px;
    transform: translate(25%, 25%);
}

.style {
    width: 2rem;
    height: 2rem;
    position: relative;
}

/* @meadia query css */

@media screen and (max-width: 370px) {
    .searchbtn {
      width: 120px;
    }
    .logo-img{
        width: 80px;
        height: 40px;
    }
  }