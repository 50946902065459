/* .seticon{
    background-color: grey;
    border-radius: 10px;
} */
.bg-white{
    width: 275;
}
.headercontent{
    margin-top: 7px;
    margin-bottom: 40px;
    margin-left: 25px;
}

/* .orderitems .orderpic{
    width: 70%;
    height: 85px;
    border-radius: 12px;
}
.orderitems{
    margin-left: 25px;
} */
/* .orderpic img{
    width: 100px;
} */

/* .orderpic{
    margin-bottom: 30px;
} */

/* .quantity{
    margin-top: 47px;
    margin-left: 25px;
} */

/* .totalsale {
    border-bottom: 1px dotted;
    padding-bottom: 10px;
} */

.total{
    padding-top: 10px;
}
/* .totalgrid{
    background-color: #ececf4;
    border-radius: 10px;
    margin-left: 20px;
   
} */

.shipping{
    margin-top: 20px;
    /* background-color: #ececf4; */
    border-radius: 10px;
    margin-left: 0px;
    padding: 10px;
   
}

.buttonstyle{
    width: 100%;
    color: white;
    margin-top: 50px;
    background-color: orange;
    border-radius: 10px;
}

.form_error{
    font-size: 9px;
    color:red;
}
/* .ordername{
    margin: 2px;
} */
.paymentbtn{
    margin-left: 25px;
}
/* 
.addbutton{
    border-radius: 8px;
    margin-bottom: 4px;
}

.minusbutton{
    border-radius: 8px;
} */
button[class*=bttn] {
    width: 30px;
    height: 30px;
    background-color: #E1E8EE;
    border-radius: 6px;
    border: none;
    cursor: pointer;
}