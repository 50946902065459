.content{
    height: auto;
    padding-left: 30px;
    padding-right: 30px;
    
}

/* span{
    color: #bfbfc1;
  } */

