.avilable-item{
    margin: 0 20px;
}
.productcard{
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 0%);
  height: 100%;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
  width: 100%;
}
.contentcard{
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 0%);
  padding: 16px;
  height: 100%;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
  }

  .choice_btn{
    line-height: 22px;
    height: 22px;
    font-size: 11px;
    text-overflow: ellipsis;
    overflow: hidden!important;
    white-space: nowrap;
    display: block;
  }
  .choice{
    position: relative;
    height: 22px;
    z-index: 0;
    float: left;
    padding: 0 6px 0 10px;
    max-width: 130px;
    /* margin-bottom: 10px; */
  }
  .choice::after{
    content: "";
      width: 10px;
      height: 22px;
      border-right: 10px solid transparent;
      border-top: 22px solid;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      right: -10px;
  }
  .outofstack_btn{
    line-height: 22px;
    height: 22px;
    font-size: 11px;
    text-overflow: ellipsis;
    overflow: hidden!important;
    white-space: nowrap;
    display: block;
  }
  .outofstack{
    position: relative;
    height: 22px;
    z-index: 0;
    float: left;
    padding: 0 6px 0 10px;
    max-width: 130px;
    /* margin-bottom: 10px; */
  }
  /* .outofstack::after{
    content: "";
      width: 10px;
      height: 0px;
      border-right: 10px solid transparent;
      border-top: 0px solid;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      right: -10px;
  } */
  /* .contentcard_product_popup {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 0%);
    padding: 16px;
    height: 510px;
    border-radius: 10px;
    background-color: white;
    overflow: hidden;
  } */
  .pic {
    position: relative;
  }
  .product_title{
    font-size: 14px;
    color: #030303;
    font-weight: bold;
    margin-top: 5px;
  }
  .popular_discount {
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    color: #030303;
    margin-right: 4px;
}
.rupee_symbol {
  font-size: 13px;
}
.product-unitweight{
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
}
.popular_mrp {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  color: #828282;
  text-decoration-line: line-through;
  margin-right: 5px;
}
.popular_price-section .popular_mrp {
  color: #828282;
  text-decoration-line: line-through;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-right: 4px;
}
.popular_percent {
  padding: 2px 4px;
  background: #FFEC6B;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.15px;
}

.btn-bottom{
  border-bottom: 0px;
}

.nodata{
  text-align: center;
 
}

/* Scroll bar css */

::-webkit-scrollbar{
  width: 6px;
}

::-webkit-scrollbar-track{
  background-color: #fff;
}
::-webkit-scrollbar-thumb{
  background-color: lightgray;
  border-radius: 50px;
}
