.popup {
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
  }
  .popup_inner {
    position: absolute;
    left: 15%;
    right: 15%;
    top: 2%;
    margin: auto;
    border-radius: 20px;
    background: white;
    padding: 20px;
  }
  .orderStatus_popup {
    position: absolute;
    left: 15%;
    right: 15%;
    top: 40%;
    margin: auto;
    border-radius: 20px;
    background: white;
    padding: 20px;
  }
  .product_popupinner {
    position: absolute;
    left: 15%;
    right: 15%;
    top: 13%;
    bottom: 2%;
    margin: auto;
    border-radius: 10px;
    background: white;
    padding: 20px;
    min-height: 520px;
  }
.popup_description{
  overflow-y: scroll;
  height: 425px;
  padding-bottom: 8px;
  padding-right: 6px;
  font-size: small;
  word-wrap: break-word;
  white-space: pre-line;
  text-align: justify;
 
}

.choice_pop{
  position: relative;
  height: 26px;
  z-index: 0;
  float: left;
  padding: 0 6px 0 10px;
  max-width: 130px;
  background-color: rgb(36, 36, 36);
  /* margin-bottom: 13px; */
}
.choice_pop::after{
  content: "";
    width: 10px;
    height: 26px;
    border-right: 10px solid transparent;
    border-top: 26px solid;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: -10px;
}
.choice_pop_btn{
  line-height: 26px;
  height: 26px;
  font-size: 12px;
  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden!important;
  white-space: nowrap;
  display: block;
}
.choice-pt{
  padding-top: 2rem!important;
}


/* Popup @media css  */
  
@media screen and (max-width: 380px) {
  .popup_description{
        height: 220px;
  }
}

@media screen and (min-width: 381px) {
  .popup_description{
        height: 180px;
  }
}

@media screen and (min-width: 500px) {
  .popup_description{
        height: 150px;
  }
}

@media screen and (min-width: 700px) {
  .popup_description{
        height: 250px;
  }
}