
.buttons .btn{
    background-color: white;
    /* margin-right: 50px; */
    width: 150px;
    /* text-align: center; */
  
  }
  .category-item{
    /* box-shadow: 0 2px 4px 0 rgb(128 128 128 / 30%);
    border-radius: 10px;
    padding: 14px 7px;
    height: 100%;
    display: flex; */  
    /* transition: .5s ease;
    justify-content: center;
    align-items: center; */
    margin-right: 15px;
   }
   .image {
    width:20px;
    height:20px;
    /* border-radius: 5px;
    display: inline; */
   }
   .category-title{
    /* font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 200px;
    justify-content: center;
    text-align: left;    
    margin-left: 8px; */
    margin-bottom: 0px;
    display: inline;
}
