.footer{
    margin-top: 30px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    width: 100%;
}
.footer_store {
   /* text-align: center;
    justify-content: center;*/
  }
.powered_title {
    text-align: center;
    justify-content: center;
	padding: 20px 0;
  }
  .powered_title a{
	color: #1151d3;
	text-decoration: none;
  }
.powered_title a:hover{
	color: #1151d3;
  }
.footer_developer{
    margin-top: 20px;
    border-radius: 10px;  
}

.footer-social{
	margin-top: 20px;
}

.footer-social a{
	display: inline-block;
	width: 32px;
	height: 32px;
	text-align: center;
	border-radius: 50%;
	padding-top: 2px;
	margin-right: 8px;
	transition: all 0.3s;
}

/* .footer-social a:hover{
	background: #F26901;
	color: #fff;
} */
